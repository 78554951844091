import React from 'react';
import './TOS.css';

const TOS = () => {
  return (
    <div className='section-page' style={{ display: "flex", justifyContent: "center", padding: "24px 0" }}>
      <div className='content content-tos'>
        <h1>Terms & Conditions</h1>

        <h2>Legal Notices</h2>
        <p>The content provided on our platform is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the accuracy, reliability, suitability, or availability of the information contained on our platform. Any reliance you place on such information is therefore strictly at your own risk.</p>

        <h2>Warranty Disclaimer</h2>
        <p>Our platform is provided "as is" and without warranties of any kind, whether express or implied. We do not warrant that our platform will be uninterrupted, secure, or error-free. We make no warranties about the accuracy, reliability, completeness, or timeliness of the content on our platform.</p>

        <h2>General</h2>
        <ol>
          <li>Use of Our Platform: You may use our platform for the intended purposes only. You agree not to use our platform for any illegal, unauthorized, or prohibited activities.</li>
          <li>Intellectual Property: All intellectual property rights in our platform, including but not limited to copyrights, trademarks, and patents, are owned by us or our licensors. You may not use, reproduce, or distribute any content from our platform without our prior written permission.</li>
          <li>Limitation of Liability: We shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use of our platform, even if we have been advised of the possibility of such damages.</li>
          <li>Data Security: We implement reasonable technical and organizational measures to protect the security of your data. However, we cannot guarantee that unauthorized third parties will never be able to defeat our security measures or misuse your data. You acknowledge and accept this risk.</li>
          <li>Indemnification: You agree to indemnify and hold us harmless from any claims, liabilities, damages, losses, or expenses arising out of your use of our platform or any violation of these terms and conditions.</li>
          <li>Modifications: We reserve the right to modify or discontinue our platform at any time without prior notice. We may also revise these terms and conditions from time to time. By continuing to use our platform after any modifications or revisions, you agree to be bound by the updated terms and conditions.</li>
          <li>Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of . Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Delaware.</li>
        </ol>

        <h2>Disclaimer</h2>
        <p>The information provided on our platform is for general informational purposes only and does not constitute legal, financial, or professional advice. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information contained on our platform. Any reliance you place on such information is therefore strictly at your own risk.</p>

        <p>If you require legal, financial, or professional advice, you should consult with a qualified professional in the relevant field.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about these terms and conditions, please feel free to contact us at <a href="mailto:info@kwazii.app">info@kwazii.app</a>.</p>

      </div>
    </div>
  );
};

export default TOS;
