import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-us-page section-page">
      <div className="frame-2">
        <div className="tittle-descriptions">
          <div className="tittle">
            <div className="text-wrapper-4">Our Mission</div>
          </div>
        </div>
        <div className="frame-3">
          <div className="div-2">
            <div className="group">
              <p className="at-kwazii-our">
                At Kwazii, our mission is to harness the power of AI to assist rather than replace, working
                symbiotically with professionals to streamline information discovery and enhance decision-making.{" "}
                <br />
                <br />
                We transform complex customer service data into clear, actionable insights. We empower businesses to
                harness customer feedback, to facilitate their product refinement and market excellence.
              </p>
            </div>
          </div>
          <div className="group-2 funnel">
            <img style={{ width: "100%" }} src="/assets/pipeline-flow.gif" alt="Pipeline" className="pipeline" />
          </div>
        </div>
      </div>
      <div className="frame-7">
        <div className="frame-8">
          <div className="tittle-descriptions-2">
            <div className="text-wrapper-6">Our Vision</div>
          </div>
          <p className="p">
            Our vision is to pioneer a future where AI and human intelligence converge to create an unparalleled
            customer support experience. We transform complex customer service data into clear, actionable insights.
            We empower businesses to harness customer feedback, to facilitate their product refinement and market
            excellence.
          </p>
        </div>
        <div className="frame-9">
          <div className="feature">
            <img className="frame-10" alt="Frame" src="/assets/frame-427320740-1.png" />
            <div className="group-10">
              <div className="frame-11">
                <div className="text-wrapper-9">Co-pilot in every support professional’s journey</div>
                <p className="text-wrapper-8">
                  By providing them with real-time, data-driven guidance and insights. We envision a wold where
                  businesses not only resolve customer queries efficiently but also leverage these interactions to
                  improve their offerings.
                </p>
              </div>
            </div>
          </div>
          <div className="feature">
            <img className="frame-10" alt="Frame" src="/assets/frame-427320740.png" />
            <div className="group-10">
              <div className="frame-11">
                <div className="text-wrapper-9">Illuminating Hidden Patterns</div>
                <p className="text-wrapper-8">
                  By illuminating hidden patterns in customer feedback, we aim to transform support data into a
                  catalyst for business evolution and growth. Every piece of customer data
                  becomes a stepping stone towards product excellence and customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-12">
        <div className="tittle-wrapper">
          <div className="tittle-2">
            <div className="text-wrapper-4">Our Core Values</div>
          </div>
        </div>
        <div className="frame-13">
          <div className="frame-14">
            <div className="frame-15">
              <div className="frame-16">
                <img
                  className="img-2"
                  alt="Auto awesome"
                  src="/assets/auto-awesome@2x.png"
                />
                <div className="text-wrapper-10">Innovation</div>
              </div>
              <p className="text-wrapper-11">
                We foster a culture of innovation, constantly exploring new technologies and methodologies to push
                the boundaries on the use of AI. We embrace change and strive for continuous improvement, always
                seeking better ways to serve our users in content search, content generation, and content
                validation.
              </p>
            </div>
            <div className="frame-17">
              <div className="frame-16">
                <img
                  className="img-2"
                  alt="Account circle"
                  src="/assets/account-circle@2x.png"
                />
                <div className="text-wrapper-10">User-Centric Approach</div>
              </div>
              <p className="text-wrapper-11">
                Our users are at the heart of everything we do. We are committed to understanding their needs,
                preferences, and pain points to develop solutions that truly enhance their user&#39;s experience. We
                value feedback and actively engage with our users to ensure their voices are heard and incorporated
                into our platform, covering content search, content generation, and content validation.
              </p>
            </div>
          </div>
          <div className="frame-14">
            <div className="frame-17">
              <div className="frame-16">
                <img
                  className="img-2"
                  alt="Chat bubble"
                  src="/assets/chat-bubble@2x.png"
                />
                <div className="text-wrapper-10">Human-like Conversations</div>
              </div>
              <p className="text-wrapper-11">
                We believe in making technology feel human. Our platform leverages Generative AI to create natural,
                conversational interactions that mimic real-life conversations. By focusing on the user&#39;s intent
                and context, we aim to provide a personalized and engaging experience that is both efficient and
                enjoyable in content search, content generation, and content validation.
              </p>
            </div>
            <div className="frame-15">
              <div className="frame-16">
                <img className="img-2" alt="Bolt" src="/assets/bolt@2x.png" />
                <div className="text-wrapper-10">Relevance &amp; Simplicity</div>
              </div>
              <p className="text-wrapper-11">
                We strive to deliver the most relevant content and features to our users, simplifying the product
                search process. Through advanced algorithms and machine learning techniques, we filter out noise and
                present users with the most accurate and useful information, eliminating the need for endless
                scrolling and browsing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
