import React, { useEffect } from 'react';
import './Changelog.css';

const Changelog = () => {
  useEffect(() => {
    window.noticeable.render('widget', 'oUv47XduWMim7eIk4J1F');
  }, []);

  return (
    <div className='content-changelog section-page' style={{ display: "flex", flexDirection:"column", justifyContent: "center", padding: "24px 0" }}>
      <div style={{display:"flex", justifyContent: "center"}}>
        <span style={{ fontSize: "18px", fontWeight: "bold", background: "#f7f7f7", padding: "10px", borderRadius: "4px"}}>
        Check out our dedicated  <a  style={{ fontSize: "18px",fontWeight: "bold" }}className="text-wrapper-22" target='_blank' rel="noreferrer" href='https://blog.kwazii.app/'>blog site</a>
          </span>
        </div>
      <div id="noticeable-newspage-embed"></div>
    </div>

    //   <div className='content content-changelog'>
    //     <h2>What's new</h2>
    //     <span className='sub-header'>New updates and improvements to Kwazii</span>

    //     <p className='date'>📅 September 10th, 2023</p>
    //     <div className='announce-content'>
    //       <p>We are excited to share the latest updates and improvements to our platform as of September 9th. Our team has been hard at work to enhance your experience, and we are thrilled to introduce exciting features and enhancements. Let&#39;s dive right in! </p>
    //       <p>One of our key focuses has been addressing hallucination in responses. We have made significant progress in distinguishing between different types of errors:</p>
    //       <ul>
    //         <li><strong>Wrong Answer vs. No Answer:</strong> Our system now differentiates between providing incorrect information and not having an answer to a user&#39;s query.</li>
    //       </ul>
    //       <ul>
    //         <li><strong>Wrong Word vs. Right Word</strong>: We&#39;ve improved accuracy <strong>to the level of a single word</strong> by recognizing when a response contains the wrong word but still conveys the correct meaning.</li>
    //       </ul>


    //       <p><strong>Analytics on Search </strong></p>
    //       <p>Gain insights into your <strong>product’s performance</strong> with our new analytics feature. Track what users are asking, what content is potentially missing or needs to be updated in your knowledge base. But more importantly you can now see user intents over time. Tie this to your product/feature releases and you have another signal into what needs improvement.</p>
    //       <p><strong>No Knowledge Answer</strong></p>
    //       <p>Sometimes, the best answer is admitting when we don&#39;t know. Our system now provides responses indicating a lack of knowledge when necessary. </p>
    //       <p><strong>One-Click Generate KB Article for Unanswered Questions</strong></p>
    //       <p>Creating knowledge base articles has never been easier. With just one click, you can generate informative articles based on unanswered questions, ensuring your knowledge base is always up to date.</p>

    //       <p>🔮 <strong>What&#39;s in the Pipeline?</strong> 🔮</p>
    //       <ol>
    //         <li><p><strong>Historical Data Analytics</strong> 🤖💬
    //           Understand trends and patterns in user interactions with historical data analytics. This feature empowers you to make data-driven decisions and continuously improve your content 📊📉</p>
    //         </li>
    //         <li><p><strong>Progress on Auto Chat Bot </strong>
    //           We&#39;re making steady progress on our auto chat bot feature, which will automate responses and provide seamless support to your users.</p>
    //         </li>
    //       </ol>
    //     </div>

    //     <p className='date'>📅 August 25th, 2023</p>
    //     <div className='announce-content'>
    //       <p>📣 <strong>What&#39;s New in Our Latest Update</strong> </p>
    //       <p>We&#39;re excited to introduce new features and improvements to enhance your experience with our platform! Here&#39;s a rundown of the latest updates:</p>
    //       <p><strong>Insights for Search Application</strong> 👀<br />
    //         Get ready to dive into data! Our latest update brings you insightful metrics for the search application. Now, you can easily track the number of answered questions, review the list of answered questions, analyze intents, and observe the distribution of intent changes over time. Stay on top of your search application&#39;s performance effortlessly.</p>
    //       <p><strong>Application Enable/Disable Functionality</strong> 🔄<br />
    //         Managing your applications just got simpler. You can now enable or disable applications directly from the platform. No more hassles of making changes to your production application – you&#39;re in control with just a few clicks.</p>
    //       <p><strong>Enhanced Safeguards Against Hallucination</strong> 🔒<br />
    //         Your peace of mind is our priority. We&#39;ve reinforced our system with improved safeguards to mitigate any chances of hallucination. Rest assured that your interactions remain accurate and reliable.</p>
    //       <p><strong>Create Knowledge Base Articles in the Playground</strong> 📚<br />
    //         Knowledge sharing made easy! You can now create knowledge base articles directly in the playground. Streamline your content creation process and enrich your knowledge base effortlessly.</p>
    //       <p><strong>Expanded Reamaze Module</strong> 📝<br />
    //         Kwazii&#39;s capabilities are expanding! The updated Reamaze module goes beyond suggesting answers. Now, Kwazii can provide concise summaries of next steps, customer pain points, and suggested improvements. It&#39;s more than just an answer – it&#39;s a holistic solution.</p>
    //       <p><strong>Seamless Feedback Submission</strong> 💬<br />
    //         Your feedback matters, and we&#39;ve made sure it&#39;s easier than ever to provide it. You can now submit feedback on all canvases – whether you&#39;re in the playground, search, or custom module. Your insights help us shape the future of our platform.</p>
    //       <p><strong>Bug Fixes and Performance Enhancements</strong> 🐞<br />
    //         We&#39;ve been hard at work squashing bugs and optimizing performance to ensure your experience is as smooth as possible. Your interactions with the platform are now even better.</p>
    //       <p>🔮 <strong>What&#39;s in the Pipeline?</strong> 🔮
    //         We&#39;re not stopping here! We&#39;re actively working on two major fronts to bring you even more value:</p>
    //       <ol>
    //         <li><p><strong>No-Code Setup for Fully Autonomous Chatbot with Agent Handoff</strong> 🤖💬
    //           Get ready to set up a fully autonomous chatbot with seamless agent handoff – all without writing a single line of code.</p>
    //         </li>
    //         <li><p><strong>Product Intelligence through Customer Feedback Analysis</strong> 📊🚀
    //           We&#39;re diving deep into customer feedback to power up our product intelligence. Expect insights that will help you tailor your offerings to your audience&#39;s needs.</p>
    //         </li>
    //       </ol>
    //     </div>

    //     <p className='date'>📅 August 5th, 2023</p>
    //     <div className='announce-content'>
    //       <p>🎉 <strong>What&#39;s New in Our Search Tool!</strong> 🎉</p>
    //       <p>We&#39;re thrilled to introduce an array of exciting updates to our search tool, designed to transform your user experience and elevate the way you interact with information. Here&#39;s a rundown of the latest enhancements:</p>
    //       <p><strong>Support for Multi-Turn Conversation in Search</strong> 💬🔍<br />
    //         Conversations are at the heart of meaningful interactions, and now our search tool supports multi-turn conversations! Engage in fluid, natural exchanges that lead to more insightful search results than ever before.</p>
    //       <p><strong>Learn More/Citations in Search Results</strong> 📚🔗<br />
    //         We believe in providing you with comprehensive knowledge. That&#39;s why we&#39;ve enriched our search results with &quot;Learn More&quot; links and citations. Delve deeper into your topics of interest and access credible references for a well-rounded understanding.</p>
    //       <p><strong>Suggestion Followup Questions</strong> 🤔❓<br />
    //         Exploring related subjects has never been smoother. Our search tool now suggests intelligent follow-up questions, guiding you effortlessly through interconnected topics and expanding your horizons.</p>
    //       <p><strong>Ability to Customize the Look and Feel of the Search Widget</strong> 🎨✨<br />
    //         Personalization meets functionality! You now have the power to tailor the appearance of the search widget to harmonize seamlessly with your brand and website theme. A search experience that&#39;s as unique as your identity.</p>
    //       <p><strong>Ability to Specify a Custom System Prompt</strong> 🗣️🎙️<br />
    //         Your search journey, your rules. Craft a custom system prompt that aligns with your specific requirements. Highlight areas of interest, steer users effectively, and curate a search adventure that speaks directly to your audience.</p>
    //       <p><strong>Ability to Specify a Placeholder</strong> 📝🔤<br />
    //         Searching is now even more user-friendly. Assist your users with hints, examples, or context by setting up a placeholder inside the search bar. The search process becomes intuitive and efficient.</p>
    //       <p>These updates are all about making your interaction with our search tool more intuitive, informative, and tailored to your needs. Experience the new possibilities today and discover a whole new way to engage with knowledge. Happy searching! 🚀🔍</p>
    //     </div>

    //   </div>
    // </div>
  );
};

export default Changelog;
