import React from 'react';

const APIReferencePage = () => {
    return (
        <div className='introduction-subpage'>
            <section>
                <h1>Documentation</h1>
                <p className='head-p'>Welcome to Kwazii documentation.</p>
            </section>
            <h2>Overview</h2>
            <p className='h-content'>Content for section 1 goes here.</p>
            <h2>Section 2</h2>
            <p className='h-content'>Content for section 2 goes here.</p>
        </div>
    );
};

export default APIReferencePage;
