import React from "react";
import { PrimaryButton } from "../../components/PrimaryButton";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Avatar, Box } from "@mui/material";
import "./Home.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      '/assets/recording-2023-11-03-at-14-53-1.gif',
  },
  {
    label: 'Bird',
    imgPath:
      '/assets/recording-2023-11-03-at-14-59-1.gif',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      '/assets/recording-2023-11-03-at-15-05-1.gif',
  },
];

export const Home = () => {
  return (
    <div className="home-page section-page">
      <div className="div-2">
        <div className="hero-title">
          <div className="div-3">
            <p className="unlock-actionable">
              Unlock Actionable Insights
              <br />
              Enhance Customer Experience
            </p>
            <p className="your-one-stop">
              Your one-stop customer service data platform.
              <br />
              Making AI accessible and actionable for your customer service data.
            </p>
          </div>
          <PrimaryButton className="design-component-instance-node" lPrimaryButton="enabled" />
        </div>
        <AutoPlaySwipeableViews
          axis={'x'}
          enableMouseEvents
          interval={8000}
          style={{ maxWidth: "1468px" }}
        >
          {images.map((step) => (
            <div key={step.label} >
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                  borderRadius: "10px"
                }}
                src={step.imgPath}
                alt={step.label}
              />
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </div>
      <div className="div-4">
        <div className="div-3">
          <div className="div-5">
            <div className="text-wrapper-4">FEATURES</div>
            <p className="text-wrapper-5">Leverage AI to improve your business</p>
          </div>
          <p className="p">
            We enable companies to apply AI at important steps of the customer journey. Here’s what Kwazii can do with
            your customer service data.
          </p>
        </div>
        <div className="features-frame">
          <div className="feature">
            <img
              className="frame-5"
              alt="Frame"
              src="/assets/conversational-analytics.png"
            />
            <div className="div-wrapper">
              <div className="frame-6">
                <div className="text-wrapper-6">Conversational Analytics</div>
                <p className="text-wrapper-7">Discover insights, solutions and patterns that might go unnoticed.</p>
              </div>
            </div>
          </div>
          <div className="feature">
            <img
              className="frame-5"
              alt="Frame"
              src="/assets/plg-analytics.png"
            />
            <div className="div-wrapper">
              <div className="frame-6">
                <div className="text-wrapper-6">Growth Analytics</div>
                <p className="text-wrapper-7">
                  Convert raw support dialogues into actionable insights and elevate retention.
                </p>
              </div>
            </div>
          </div>
          <div className="feature">
            <img
              className="frame-5"
              alt="Frame"
              src="/assets/generative-search.png"
            />
            <div className="div-wrapper">
              <div className="frame-6">
                <div className="text-wrapper-6">Generative Search</div>
                <p className="text-wrapper-7">
                  Craft applications, customize experience and connect with seamless integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-2 how-it-works">
        <div className="div-3">
          <div className="tittle-descriptions">
            <div className="tittle">
              <div className="text-wrapper-14">HOW IT WORKS</div>
              <div className="text-wrapper-13">CRM to Actionable Insights</div>
            </div>
          </div>
          <p className="text-wrapper-15">
            Kwazii converts your CRM data into tailored insights, analytics, and workflows in minutes!
          </p>
        </div>


        <div className="group-2 funnel">
          <img src="/assets/pipeline-flow.gif" alt="Pipeline" className="pipeline" />
        </div>

      </div>
      <div className="div-4">
        <div className="div-5">
          <div className="text-wrapper-4">WHAT OUR USERS SAY</div>
          <div className="text-wrapper-5">Trusted by customer-focused companies</div>
        </div>
        <div className="testimonial-text">
          <p className="we-decided-to-start">
            <span className="span">
              ‘We decided to start using Kwazii for customer insights, search, and chat because it was super easy to
              integrate. And let me tell you, we&#39;re really happy that we did! In the past, we had so many incomplete
              spreadsheets for tracking customer KPIs and pain points. But now, thanks to Kwazii, we finally have a
              clear view of the key insights and can take actionable steps to address them.
              <br />
              <br />
            </span>
            <span className="text-wrapper-16">
              One feature that our support team absolutely loves is the ability to create support content with just one
              click
            </span>
            <span className="span">
              . This has been especially helpful for the most commonly asked and unanswered questions in our knowledge
              base library. Plus, the support issue categories have been a game-changer.{" "}
            </span>
            <span className="text-wrapper-16">
              They&#39;ve allowed us to quickly identify areas for improvement and make necessary updates to our
              product.
              <br />
            </span>
            <span className="span">
              <br />
              Before using Kwazii, it often felt like we were playing a game of whack-a-mole when dealing with customer
              issues. But now,{" "}
            </span>
            <span className="text-wrapper-16">
              we&#39;re able to prioritize actions and make meaningful improvements to our customer support experience
              and product as a whole
            </span>
            <span className="span">
              .<br />
              <br />I have to say, what impressed us the most was the passion, responsiveness, and innovation of the
              Kwazii team. Keep up the fantastic work!’
            </span>
          </p>
          <div className="testimonial-giver">
            <Avatar src="/assets/kandarp.jpeg"></Avatar>
            <div className="frame-18">
              <div className="text-wrapper-17">Kandap Jani</div>

              <div className="text-wrapper-18">Chief Technology Officer, PupPod</div>
            </div>
          </div>
        </div>
      </div>
      <div className="closing-CTA-section">
        <div className="frame-19">
          <div className="frame-20">
            <div className="tittle-wrapper">
              <div className="tittle-2">
                <div className="text-wrapper-19">GET STARTED TODAY</div>
              </div>
            </div>
            <div className="tittle-descriptions-2">
              <div className="tittle-3">
                <p className="text-wrapper-20">Try our Beta version now!</p>
                <PrimaryButton className="design-component-instance-node" lPrimaryButton="enabled" />
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};


export default Home;