/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PrimaryButton = ({ lPrimaryButton, className, label = "Book Demo" }) => {
  return (
    <a href="#demo" onClick={() => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/shailesh_kwazii/30min' }); return false; }}>
      <div className={`primary-button ${lPrimaryButton} ${className}`}>
        <div className="text-wrapper">{label}</div>
      </div>
    </a>
  );
};

PrimaryButton.propTypes = {
  lPrimaryButton: PropTypes.oneOf(["disabled", "pressed", "hover", "enabled"]),
};
