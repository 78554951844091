import React from 'react';
import "./DocumentionSubpage.css"

const IntroductionPage = () => {
    return (
        <div className='documentionsubpage-subpage'>
            <section>
                <h1>Quick-start</h1>
                <p className='head-p'>A quick-start guide to get you up and running</p>
            </section>
            <p className='h-content-nb'>In the following you will find a quickstart guide to get you up and running</p>


            <h2>Using script</h2>
            <p className='h-content' style={{ paddingTop: 0 }}>
                <p>Our mission is to revolutionize product search with conversational, human-like experiences. Empowering users with relevant content and user-friendly features, our platform utilizes Generative AI to enhance the product discovery process. By bridging the gap between humans and technology, we create a seamless search experience. Through conversational interactions, we set a new standard for user engagement and satisfaction.</p>

                <p>At Kwazii, our core values drive us. With a user-centric approach, we understand and address user needs, preferences, and pain points, enhancing their search experience. User feedback is crucial, and we actively engage to incorporate their input. We strive to make technology feel human by leveraging Generative AI, enabling natural conversations. This personalized and engaging experience is both efficient and enjoyable.</p>

                <p>Our strategic goals at Kwazii encompass diverse areas. We aim to develop an AI-powered conversational interface, refining its ability to understand and respond naturally. Enhancing the product discovery experience is paramount, achieved through advanced algorithms and machine learning techniques. We simplify navigation and present relevant content, eliminating endless scrolling. Fostering user engagement and satisfaction is crucial, involving feedback and community building. We constantly improve and innovate to stay at the forefront of Generative AI technology.</p>
            </p>
            <br />
            <h2>Step-by-step (manually)</h2>
            <p className='h-content' style={{ paddingTop: 0 }}>
                <p>Our mission is to revolutionize product search with conversational, human-like experiences. Empowering users with relevant content and user-friendly features, our platform utilizes Generative AI to enhance the product discovery process. By bridging the gap between humans and technology, we create a seamless search experience. Through conversational interactions, we set a new standard for user engagement and satisfaction.</p>

                <p>At Kwazii, our core values drive us. With a user-centric approach, we understand and address user needs, preferences, and pain points, enhancing their search experience. User feedback is crucial, and we actively engage to incorporate their input. We strive to make technology feel human by leveraging Generative AI, enabling natural conversations. This personalized and engaging experience is both efficient and enjoyable.</p>

                <p>Our strategic goals at Kwazii encompass diverse areas. We aim to develop an AI-powered conversational interface, refining its ability to understand and respond naturally. Enhancing the product discovery experience is paramount, achieved through advanced algorithms and machine learning techniques. We simplify navigation and present relevant content, eliminating endless scrolling. Fostering user engagement and satisfaction is crucial, involving feedback and community building. We constantly improve and innovate to stay at the forefront of Generative AI technology.</p>
            </p>
        </div>


    );
};

export default IntroductionPage;
