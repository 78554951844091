import React from 'react';

import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Documentation from './pages/docs/Documentation';
import Home from './pages/home/Home';
import Privacy from './pages/footer/Privacy';
import TOS from './pages/footer/TOS';
import About from './pages/about/About';
import Changelog from './pages/changelog/Changelog';
import { getPlatformEndpoint } from './utils/utils';

const AppBase = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      {/* <InfoComponent /> */}

      <div className="index">
        {/* Top section */}
        <div className="navigation-bar-p">
          <div className="navigation-bar">
            <a href="/">
              <img
                className="frame"
                alt="Frame"
                src="/assets/frame-427320603@2x.png"
              />
            </a>
            <div className="navigation-menu">
              {currentPath === "/"
                ?
                <div className="products-wrapper">
                  <div className="products">
                    <a href="/" className="div">Home</a>
                  </div>
                </div>
                : <a href="/" className="text-wrapper-2">Home</a>
              }

              {currentPath === "/about"
                ?
                <div className="products-wrapper">
                  <div className="products">
                    <a href="/about" className="div">About Us</a>
                  </div>
                </div>
                : <a href="/about" className="text-wrapper-2">About Us</a>
              }

              {currentPath === "/changelog"
                ?
                <div className="products-wrapper">
                  <div className="products">
                    <a href="/changelog" className="div">Changelog</a>
                  </div>
                </div>
                : <a href="/changelog" className="text-wrapper-2">Changelog</a>
              }
              <a className='text-wrapper-2' target='_blank' rel='noreferrer' href="https://api-docs.kwazii.app/">API</a>
              <a href={`${getPlatformEndpoint()}/login`} className="text-wrapper-3" style={{
                borderRadius: "16px",
                padding: "6px 12px",
                backgroundColor: "var(--primary-500)"
              }}>Get started</a>
            </div>
          </div>
        </div>

        {/* Route configuration */}
        <Routes>
          <Route path="/docs/*" element={<Documentation />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/about" element={<About />} />
          <Route path="/changelog" element={<Changelog />} />

          <Route path="/" element={<Home />} />
        </Routes>

        {/* Footer section */}
        <footer className="footer">
          <div className="frame-22">
            <div className="div-6">
              <div className="text-wrapper-21">Product</div>
              <div className="frame-23">
                <div className="text-wrapper-22">
                  <a href="#demo" onClick={() => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/shailesh_kwazii/30min' }); return false; }}>Book Demo</a>
                </div>
                <div className="text-wrapper-23"><a href="https://api-docs.kwazii.app/">API</a></div>
              </div>
            </div>
            <div className="div-6">
              <div className="text-wrapper-21">Company</div>
              <div className="frame-23">
                <div className="text-wrapper-22"><a href="/about">About Us</a></div>
                <div className="text-wrapper-22"><a href="https://blog.kwazii.app/" target='_blank' rel="noreferrer">Blog</a></div>
                <div className="text-wrapper-23"><a href="/changelog">Changelog</a></div>
                <div className="text-wrapper-23"><a href="mailto:hello@kwazii.app">Contact Us</a></div>
              </div>
            </div>
            <div className="div-6">
              <div className="text-wrapper-21">
                Follow Us
              </div>
              <div className="frame-23">
                <div className="text-wrapper-22">
                  <a href="https://www.linkedin.com/company/kwaziiinc">LinkedIn</a>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-24">
            <div className="frame-25">
              <div className="privacy-terms">
                <div className="text-wrapper-24"><a href="/privacy">Privacy Policy</a></div>
                <div className="text-wrapper-25">|</div>
                <div className="text-wrapper-24"><a href="/tos">Terms &amp; Conditions</a></div>
              </div>
              <p className="text-wrapper-22">© 2023 Kwazii™. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

function App() {
  return (
    <Router>
      <AppBase></AppBase>
    </Router>
  );
}

export default App;
