import React from 'react';
import { Routes, Route } from 'react-router-dom';
import "./Documentation.css"

// Import individual page components
import IntroductionPage from './IntroductionPage';
import QuickStartPage from './QuickStartPage';
import APIReferencePage from './APIReferencePage';

const Documentation = () => {
    return (
        <div className='content documentation-page'>
            <div className="left-pane">
                <ul>
                    <li>
                        <span className='head first'>
                            Getting Started
                        </span>
                    </li>
                    <li>
                        <a href="/docs" className={window.location.pathname === '/docs' ? 'selected' : ''}>
                            Introduction
                        </a>
                    </li>
                    <li>
                        <a href="/docs/quickstart" className={window.location.pathname === '/docs/quickstart' ? 'selected' : ''}>
                            Quick Start
                        </a>
                    </li>
                    <li>
                        <a href="/docs/create-account" className={window.location.pathname === '/docs/create-account' ? 'selected' : ''}>
                            Creating an account
                        </a>
                    </li>
                    <li>
                        <a href="/docs/create-apikey" className={window.location.pathname === '/docs/create-apikey' ? 'selected' : ''}>
                            Creating an API key
                        </a>
                    </li>
                    {/* API head */}
                    <li>
                        <span className='head'>
                            API
                        </span>
                    </li>
                    <li>
                        <a href="/docs/api-overview" className={window.location.pathname === '/docs/api-overview' ? 'selected' : ''}>
                            Overview
                        </a>
                    </li>
                    <li>
                        <a href="/docs/api-concept" className={window.location.pathname === '/docs/api-concept' ? 'selected' : ''}>
                            Concept
                        </a>
                    </li>
                    <li>
                        <a href="/docs/api-dataingestion" className={window.location.pathname === '/docs/api-dataingestion' ? 'selected' : ''}>
                            Data injestion
                        </a>
                    </li>
                    <li>
                        <a href="/docs/api-chat" className={window.location.pathname === '/docs/api-chat' ? 'selected' : ''}>
                            Chat
                        </a>
                    </li>
                    <li>
                        <a href="/docs/api-chat-conversation" className={window.location.pathname === '/docs/api-chat-conversation' ? 'selected sub-li' : 'sub-li'}>
                            Conversation
                        </a>
                    </li>{/* Integrations */}
                    <li>
                        <span className='head'>
                            Integrations
                        </span>
                    </li>
                    <li>
                        <a href="/docs/integrations-web" className={window.location.pathname === '/docs/integrations-web' ? 'selected' : ''}>
                            Web
                        </a>
                    </li>
                    <li>
                        <a href="/docs/integrations-reamaze" className={window.location.pathname === '/docs/integrations-reamaze' ? 'selected' : ''}>
                            Re:amaze
                        </a>
                    </li>
                    <li>
                        <a href="/docs/integrations-s3" className={window.location.pathname === '/docs/integrations-s3' ? 'selected' : ''}>
                            AWS S3
                        </a>
                    </li>
                    <li>
                        <a href="/docs/integrations-azure-storage" className={window.location.pathname === '/docs/integrations-azure-storage' ? 'selected' : ''}>
                            Azue Storage
                        </a>
                    </li>

                    {/* Integrations */}
                    <li>
                        <span className='head'>
                            Use cases
                        </span>
                    </li>
                    <li>
                        <a href="/docs/use-case-documentation" className={window.location.pathname === '/docs/use-case-documentation' ? 'selected' : ''}>
                            Documentation
                        </a>
                    </li>
                    <li>
                        <a href="/docs/use-case-customer-support" className={window.location.pathname === '/docs/use-case-customer-support' ? 'selected' : ''}>
                            Customer Support
                        </a>
                    </li>
                    <li>
                        <a href="/docs/use-case-product-copilot" className={window.location.pathname === '/docs/use-case-product-copilot' ? 'selected' : ''}>
                            Product CoPilot
                        </a>
                    </li>
                    <li>
                        <a href="/docs/use-case-internal-traning" className={window.location.pathname === '/docs/use-case-internal-traning' ? 'selected' : ''}>
                            Internal Traning
                        </a>
                    </li>
                </ul>
            </div>
            <div className="content-pane">
                <Routes>
                    <Route path="" element={<IntroductionPage />} />
                    <Route path="quickstart" element={<QuickStartPage />} />
                    <Route path="apireference" element={<APIReferencePage />} />
                </Routes>
            </div>
            <div className="right-sidebar">
                <h3>On this page</h3>
                <p>Content for the right sidebar goes here.</p>
            </div>
        </div >
    );
}

export default Documentation;
