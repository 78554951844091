import React from 'react';
import "./DocumentionSubpage.css"

const IntroductionPage = () => {
    return (
        <div className='documentionsubpage-subpage'>
            <section>
                <h1>Documentation</h1>
                <p className='head-p'>Welcome to Kwazii documentation</p>
            </section>
            <h2>Overview</h2>
            <p className='h-content'>
                <p>Our core philosophy revolves around being an API first company. We prioritize the development and optimization of our APIs to ensure seamless integration and efficient utilization of our technology. By providing developers with the necessary tools and resources, we empower them to leverage Generative AI in their applications and services.</p>

                <p>Our mission is to democratize Generative AI, making it accessible to a wider audience. We believe that AI should not be limited to a select few, but rather be available to everyone. Through our user-friendly platforms and comprehensive documentation, we aim to bridge the gap and enable individuals and businesses to harness the power of AI for their specific needs.</p>
            </p>
        </div>

    );
};

export default IntroductionPage;
