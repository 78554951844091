export function getUrlParam(paramName) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return params.get(paramName);
}

export function getDomainName() {
    const url = new URL(window.location.href);
    return url.hostname;
}


export function getPlatformEndpoint() {
    const domainName = getDomainName();

    let url;

    if (domainName === 'localhost') {
        url = 'http://localhost:3000';
    } else if (domainName === 'stag.kwazii.app') {
        url = 'https://stag-platform.kwazii.app';
    } else {
        url = 'https://platform.kwazii.app';
    }

    console.log(`Platform endpoint: ${url}`);

    return url;
}
