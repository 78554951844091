import React from 'react';
import './Privacy.css';

const Privacy = () => {
  return (
    <div className='section-page' style={{ display: "flex", justifyContent: "center", padding: "24px 0" }}>
      <div className='content content-privacy'>
        <h1>Privacy Policy</h1>

        <p>Kwazii ("we," "us," or "our") is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you access and use our services. By using our services, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>

        <h2>Consent</h2>
        <p>By accessing and using our services, you agree to the terms of this Privacy Policy and consent to the collection, use, and disclosure of your information as outlined in this document. If you do not agree with the terms of this Privacy Policy, please discontinue using our services.</p>

        <h2>Information We Collect</h2>
        <p>We collect the minimum amount of information necessary to provide you with our services. This may include your user data, such as your name, email address, and payment information, if applicable.</p>

        <p>We may also collect and store snippets of customer data that are used to train our Generative AI models. This data may include text, documents, or other types of data that you provide to us. We handle this customer data with utmost care and ensure that it is stored securely.</p>

        <h2>How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>

        <ol>
          <li>To provide you with our services, including access to our Generative AI models and related features.</li>
          <li>To process transactions, if applicable, and fulfill your requests for products or services.</li>
          <li>To improve our services and develop new features to better serve you.</li>
          <li>To communicate with you about your account, our services, or any updates or changes to our Privacy Policy.</li>
          <li>To protect our rights and the rights of other users, and to comply with any applicable laws and regulations.</li>
        </ol>

        <p>We will not share, sell, or disclose your personal information to third parties without your consent, except as required by law or to protect our rights and the rights of others.</p>

        <h2>Data Security</h2>
        <p>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, or destruction. However, no method of data transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>

        <h2>Retention of Customer Data</h2>
        <p>We retain customer data, including the snippets used to train our Generative AI models, for as long as necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. We may also retain and use this data for analytical and research purposes, to improve our services and develop new features.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws and regulations. We encourage you to periodically review this policy to stay informed about how we protect your personal information.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please feel free to contact us at <a href="mailto:info@kwazii.app">info@kwazii.app</a>. You can also reach us on Discord for any inquiries.</p>

        <p>By using our services, you acknowledge and agree to the terms and conditions outlined in this Privacy Policy.</p>
      </div>
    </div>
  );
};

export default Privacy;
